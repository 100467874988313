
import RouteComponent from "@/components/routerComponent/RouteComponent.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "ConferenceIndex",
  components: {RouteComponent},
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
